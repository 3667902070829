import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const CancellationTermsIcon = (props) => (
  <SvgIcon
    {...props}
    width="36"
    height="39"
    viewBox="0 0 36 39"
    fill="none"
    style={{ padding: 2 }}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M24.8417 38.25L18.3333 31.7417L20.9 29.175L24.7958 33.0708L32.5875 25.2792L35.1542 27.8917L24.8417 38.25ZM8.06667 28.1667L5.5 25.6L8.43333 22.6667L5.5 19.7333L8.06667 17.1667L11 20.1L13.9333 17.1667L16.5 19.7333L13.5667 22.6667L16.5 25.6L13.9333 28.1667L11 25.2333L8.06667 28.1667ZM3.66667 37.3333C2.65833 37.3333 1.79514 36.9743 1.07708 36.2562C0.359028 35.5382 0 34.675 0 33.6667V7.99999C0 6.99166 0.359028 6.12846 1.07708 5.41041C1.79514 4.69235 2.65833 4.33332 3.66667 4.33332H5.5V0.666656H9.16667V4.33332H23.8333V0.666656H27.5V4.33332H29.3333C30.3417 4.33332 31.2049 4.69235 31.9229 5.41041C32.641 6.12846 33 6.99166 33 7.99999V19.6417L29.3333 23.3542V15.3333H3.66667V33.6667H15.125L18.7458 37.3333H3.66667ZM3.66667 11.6667H29.3333V7.99999H3.66667V11.6667Z" fill="black" />
  </SvgIcon>
);

export default CancellationTermsIcon;
