import React from 'react';
import Breadcrumbs from 'common/components/breadcrumbs/Breadcrumbs';
import Typography500 from 'common/components/Typography500';

// Material UI core
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import { Container } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    borderBottom: `1px solid ${theme.palette.grey[100]}`,
    backgroundColor: theme.palette.common.white,
    padding: theme.spacing(3, 0),
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(2, 0),
    },
    '&.notBordered': {
      padding: theme.spacing(3, 0, 6),
      borderBottom: 'unset',
      [theme.breakpoints.down('lg')]: {
        paddingBottom: theme.spacing(5),
      },
      [theme.breakpoints.down('md')]: {
        paddingBottom: theme.spacing(4),
      },
      [theme.breakpoints.down('sm')]: {
        paddingBottom: theme.spacing(3),
      },
    },
  },
}));

const PageTitle = (props) => {
  const { title, breadCrumbProps, notBordered = false } = props;
  const classes = useStyles();

  return (
    <Box className={`${classes.root} ${notBordered ? 'notBordered' : ''}`}>
      <Container>
        <Breadcrumbs data={breadCrumbProps} />
        <Typography500 variant="h2" component="h2" mt={2}>
          {title}
        </Typography500>
      </Container>
    </Box>
  );
};

export default PageTitle;
