import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const ExclusionsInsuranceProtection = (props) => (
  <SvgIcon
    {...props}
    width="37"
    height="39"
    viewBox="0 0 37 39"
    fill="none"
    style={{ padding: 2 }}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M32.5586 26.425L29.8086 23.5833C30.1752 22.6056 30.4655 21.5896 30.6794 20.5354C30.8933 19.4813 31.0002 18.4195 31.0002 17.35V8.68751L20.0002 4.56251L13.3086 7.08334L10.4669 4.24167L20.0002 0.666672L34.6669 6.16667V17.35C34.6669 18.9083 34.4912 20.4514 34.1398 21.9792C33.7884 23.507 33.2613 24.9889 32.5586 26.425ZM34.3002 38.4333L28.3419 32.475C27.1808 33.6667 25.8898 34.6674 24.469 35.4771C23.0481 36.2868 21.5586 36.9056 20.0002 37.3333C15.753 36.2639 12.2468 33.8271 9.48148 30.0229C6.7162 26.2188 5.33356 21.9944 5.33356 17.35V9.46667L0.566895 4.70001L3.13356 2.13334L36.8669 35.8667L34.3002 38.4333ZM20.0002 33.4833C21.0697 33.1472 22.0933 32.6736 23.0711 32.0625C24.0488 31.4514 24.9502 30.7333 25.7752 29.9083L9.00023 13.1333V17.35C9.00023 21.0472 10.0391 24.4083 12.1169 27.4333C14.1947 30.4583 16.8225 32.475 20.0002 33.4833Z" fill="black" />
  </SvgIcon>
);

export default ExclusionsInsuranceProtection;
