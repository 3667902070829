import React, { useEffect, useMemo, useState } from 'react';
import {
  Box, Container, Grid, makeStyles,
} from '@material-ui/core';
import { withTranslation } from 'config/i18n';
import PageTitle from 'common/components/PageTitle/PageTitle';
import InformationTile from 'views/information_module/informationView/components/InformationTile/InformationTile';

import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined';
import HelpOutlineOutlinedIcon from '@material-ui/icons/HelpOutlineOutlined';
import LicenseRequirements from 'common/icons/information/LicenseRequirements';
import CancellationTermsIcon from 'common/icons/information/CancellationTermsIcon';
import InsuranceProtectionPlan from 'common/icons/information/InsuranceProtectionPlan';
import StorefrontOutlinedIcon from '@material-ui/icons/StorefrontOutlined';
import ExclusionsInsuranceProtection from 'common/icons/information/ExclusionsInsuranceProtection';
import DamagePolicy from 'common/icons/information/DamagePolicy';
import RenterTerms from 'common/icons/information/RenterTerms';
import ListOutlinedIcon from '@material-ui/icons/ListOutlined';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import OwnerTerms from 'common/icons/information/OwnerTerms';
import Contact from 'common/icons/information/Contact';

import Head from 'next/head';
import axios, { API } from 'config/axios';
import useHandleResponse from 'utils/useHandleResponse';
import generateMetaData from 'utils/generateMetaData';

const useStyles = makeStyles((theme) => ({
  IVContainer: {
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
  IVGridWrapper: {
    padding: theme.spacing(4, 0),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(3, 0),
    },
  },
}));

const InformationView = (props) => {
  const { t, serverData } = props;
  const [seoValues, setSeoValues] = useState(serverData);
  const { handleError } = useHandleResponse();

  const getSeoValuesData = () => {
    axios
      .get(API.seoValues, {
        params: {
          preferred_seo_identifer: '/information',
        },
      })
      .then((res) => {
        setSeoValues(res.data);
      })
      .catch((error) => {
        handleError(error, false);
      });
  };
  const classes = useStyles();

  const breadCrumbProps = useMemo(
    () => ({
      links: [
        {
          text: t('fe_es_information:eagleShare'),
          path: '/',
        },
      ],
      breadCrumbsTitle: t('fe_es_information:information'),
    }),
    [],
  );

  const informationList = useMemo(
    () => [
      {
        informationTileTitle: t('fe_es_information:rent_motorcycle'),
        informationTileIcon: <HomeOutlinedIcon />,
        informationTileSubTitle: t('fe_es_information:ready_to_book'),
        informationTilePath: '/',
      },
      {
        informationTileTitle: t('fe_es_information:frequently_asked_questions'),
        informationTileIcon: <HelpOutlineOutlinedIcon />,
        informationTilePath: '/faqs',
      },
      {
        informationTileTitle: t('fe_es_information:license_requirements'),
        informationTileIcon: <LicenseRequirements />,
        informationTilePath: '/motorcycle-rental-license-requirements',
      },
      {
        informationTileTitle: t('fe_es_information:cancellation_terms_policies'),
        informationTileIcon: <CancellationTermsIcon />,
        informationTilePath: '/cancellation-terms-policies',
      },

      {
        informationTileTitle: t('fe_es_information:list_of_exclusions'),
        informationTileIcon: <ExclusionsInsuranceProtection />,
        informationTilePath: '/list-of-exclusions',
      },
      {
        informationTileTitle: t('fe_es_information:eagleShare_insurance'),
        informationTileIcon: <InsuranceProtectionPlan />,
        informationTilePath: '/eagleshare-insurance-and-protection-plan',
      },
      {
        informationTileTitle: t('fe_es_information:eagleShare_damage_policy'),
        informationTileIcon: <DamagePolicy />,
        informationTilePath: '/eagleshare-damage-policy',
      },
      {
        informationTileTitle: t('fe_es_information:eagleShare_commercial_owner_protection_policy'),
        informationTileIcon: <StorefrontOutlinedIcon />,
        informationTilePath: '/commercial-owner-protection-policy',
      },

      {
        informationTileTitle: t('fe_es_information:eagleShare_renter_terms'),
        informationTileIcon: <RenterTerms />,
        informationTilePath: '/eagleshare-renter-terms',
      },
      {
        informationTileTitle: t('fe_es_information:eagleShare_owner_terms'),
        informationTileIcon: <OwnerTerms />,
        informationTilePath: '/eagleshare-owner-terms',
      },
      {
        informationTileTitle: t('fe_es_information:eagleShare_terms_of_service'),
        informationTileIcon: <ListOutlinedIcon />,
        informationTilePath: '/eagleshare-terms-of-service',
      },
      {
        informationTileTitle: t('fe_es_information:eagleShare_Privacy_policy'),
        informationTileIcon: <LockOutlinedIcon />,
        informationTilePath: '/eagleshare-privacy-policy',
      },
      {
        informationTileTitle: t('fe_es_information:contact'),
        informationTileIcon: <Contact />,
        informationTilePath: '/eagleshare-support',
      },
    ],
    [],
  );

  useEffect(() => {
    if (!serverData) {
      getSeoValuesData();
    }
  }, []);

  return (
    <>
      <Head>{seoValues && generateMetaData(seoValues)}</Head>
      <PageTitle breadCrumbProps={breadCrumbProps} title={t('fe_es_information:information')} />
      <Container className={classes.IVContainer}>
        <Box className={classes.IVGridWrapper}>
          <Grid container spacing={2}>
            {informationList.map((item) => (
              <Grid item sm={12} md={12} lg={4} xl={3} key={item.text}>
                <InformationTile
                  informationTileTitle={item.informationTileTitle}
                  informationTileSubTitle={item.informationTileSubTitle}
                  informationTileIcon={item.informationTileIcon}
                  informationTilePath={item.informationTilePath}
                />
              </Grid>
            ))}
          </Grid>
        </Box>
      </Container>
    </>
  );
};

export default withTranslation('fe_es_information')(InformationView);
