import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const DamagePolicy = (props) => (
  <SvgIcon
    {...props}
    width="34"
    height="34"
    viewBox="0 0 34 34"
    fill="none"
    style={{ padding: 2 }}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M17 26.1667C17.5194 26.1667 17.9549 25.991 18.3062 25.6396C18.6576 25.2882 18.8333 24.8528 18.8333 24.3333C18.8333 23.8139 18.6576 23.3785 18.3062 23.0271C17.9549 22.6757 17.5194 22.5 17 22.5C16.4806 22.5 16.0451 22.6757 15.6938 23.0271C15.3424 23.3785 15.1667 23.8139 15.1667 24.3333C15.1667 24.8528 15.3424 25.2882 15.6938 25.6396C16.0451 25.991 16.4806 26.1667 17 26.1667ZM15.1667 18.8333H18.8333V7.83333H15.1667V18.8333ZM10.125 33.5L0.5 23.875V10.125L10.125 0.5H23.875L33.5 10.125V23.875L23.875 33.5H10.125ZM11.6833 29.8333H22.3167L29.8333 22.3167V11.6833L22.3167 4.16667H11.6833L4.16667 11.6833V22.3167L11.6833 29.8333Z" fill="black" />
  </SvgIcon>
);

export default DamagePolicy;
