import { Box } from '@material-ui/core';
import React from 'react';

import InformationView from 'views/information_module/informationView/InformationView';

import isCrawlers from 'common/utils/isCrawlers';
import generateHreflang from 'utils/generateHreflang';
import { serverFetch } from 'utils/serverFetch';
import { API } from 'config/axios';

const Information = (props) => {
  const { seoValues } = props;
  return (
    <>
      <Box>
        <InformationView serverData={seoValues} />
      </Box>
    </>
  );
};
Information.getInitialProps = async (ctx) => {
  let seoValues;

  const ssrRender = ctx.req ? isCrawlers(ctx.req.headers['user-agent']) : false;
  if (ssrRender) {
    const { success, data } = await serverFetch(ctx, API.seoValues, {
      preferred_seo_identifer: '/information',
    });
    if (success && data) {
      seoValues = data;
    }
  }

  // generate canonical and hrefLang
  const host = ctx.req ? ctx.req.headers.host : window.location.host;
  let url;
  if (ctx.req) {
    url = ctx.req.url;
  } else {
    url = ctx.asPath;
  }

  const headLinks = generateHreflang(url, '/information', host);

  return {
    namespacesRequired: ['fe_es_information', 'routes'],
    seoValues,
    headLinks,
    ssrRender,
  };
};
export default Information;
