import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

const TypographyFontWeight500 = withStyles(() => ({
  root: {
    fontWeight: '500',
  },
}))(Typography);

const Typography500 = (props) => {
  const { children, ...other } = props;

  return (
    <>
      <TypographyFontWeight500 {...other}>
        {children}
      </TypographyFontWeight500>
    </>
  );
};

export default Typography500;
