import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const LicenseRequirements = (props) => (
  <SvgIcon
    {...props}
    width="38"
    height="38"
    viewBox="0 0 38 38"
    fill="none"
    style={{ padding: 2 }}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M4.33317 37.3333C3.32484 37.3333 2.46164 36.9743 1.74359 36.2562C1.02553 35.5382 0.666504 34.675 0.666504 33.6667V13.5C0.666504 12.4917 1.02553 11.6285 1.74359 10.9104C2.46164 10.1924 3.32484 9.83332 4.33317 9.83332H13.4998V4.33332C13.4998 3.32499 13.8589 2.4618 14.5769 1.74374C15.295 1.02568 16.1582 0.666656 17.1665 0.666656H20.8332C21.8415 0.666656 22.7047 1.02568 23.4228 1.74374C24.1408 2.4618 24.4998 3.32499 24.4998 4.33332V9.83332H33.6665C34.6748 9.83332 35.538 10.1924 36.2561 10.9104C36.9741 11.6285 37.3332 12.4917 37.3332 13.5V33.6667C37.3332 34.675 36.9741 35.5382 36.2561 36.2562C35.538 36.9743 34.6748 37.3333 33.6665 37.3333H4.33317ZM4.33317 33.6667H33.6665V13.5H24.4998C24.4998 14.5083 24.1408 15.3715 23.4228 16.0896C22.7047 16.8076 21.8415 17.1667 20.8332 17.1667H17.1665C16.1582 17.1667 15.295 16.8076 14.5769 16.0896C13.8589 15.3715 13.4998 14.5083 13.4998 13.5H4.33317V33.6667ZM7.99984 30H18.9998V29.175C18.9998 28.6555 18.8547 28.1743 18.5644 27.7312C18.2741 27.2882 17.8693 26.9444 17.3498 26.7C16.7387 26.425 16.12 26.2187 15.4936 26.0812C14.8672 25.9437 14.2026 25.875 13.4998 25.875C12.7971 25.875 12.1325 25.9437 11.5061 26.0812C10.8797 26.2187 10.2609 26.425 9.64984 26.7C9.13039 26.9444 8.72553 27.2882 8.43525 27.7312C8.14498 28.1743 7.99984 28.6555 7.99984 29.175V30ZM22.6665 27.25H29.9998V24.5H22.6665V27.25ZM13.4998 24.5C14.2637 24.5 14.913 24.2326 15.4478 23.6979C15.9825 23.1632 16.2498 22.5139 16.2498 21.75C16.2498 20.9861 15.9825 20.3368 15.4478 19.8021C14.913 19.2673 14.2637 19 13.4998 19C12.7359 19 12.0866 19.2673 11.5519 19.8021C11.0172 20.3368 10.7498 20.9861 10.7498 21.75C10.7498 22.5139 11.0172 23.1632 11.5519 23.6979C12.0866 24.2326 12.7359 24.5 13.4998 24.5ZM22.6665 21.75H29.9998V19H22.6665V21.75ZM17.1665 13.5H20.8332V4.33332H17.1665V13.5Z" fill="black" />
  </SvgIcon>
);

export default LicenseRequirements;
