import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const OwnerTerms = (props) => (

  <SvgIcon
    {...props}
    width="35"
    height="44"
    viewBox="0 0 35 44"
    fill="none"
    style={{ padding: 2 }}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path fillRule="evenodd" clipRule="evenodd" d="M22.9165 7.33333C22.9165 11.385 19.523 14.6667 15.3332 14.6667C11.1434 14.6667 7.74984 11.385 7.74984 7.33333C7.74984 3.28167 11.1434 0 15.3332 0C19.523 0 22.9165 3.28167 22.9165 7.33333ZM0.166504 25.6667C0.166504 20.79 10.2713 18.3333 15.3332 18.3333C16.6944 18.3333 18.4204 18.511 20.2165 18.8656C18.5192 20.8568 17.4998 23.4085 17.4998 26.1905C17.4998 27.28 17.6562 28.3343 17.9484 29.3333H0.166504V25.6667Z" fill="#111111" />
    <path d="M24.9859 19.1633H27.8718C31.1926 19.1633 31.1926 23.9785 31.1926 26.0955C31.1926 29.0012 29.1369 29.9975 27.0811 29.9975H25.8556C23.7603 29.9559 21.7046 28.9597 21.7046 26.0955C21.7046 23.9785 21.7046 19.1633 24.9859 19.1633H27.8718H24.9859Z" fill="#111111" />
    <path fillRule="evenodd" clipRule="evenodd" d="M22.3859 21.9191C21.989 23.34 21.9871 25.0301 21.9871 26.0956C21.9871 27.4428 22.4654 28.3166 23.1642 28.8681C23.8754 29.4293 24.8464 29.6806 25.8584 29.7011H27.0813C28.0718 29.7011 29.032 29.4602 29.7376 28.8998C30.4307 28.3493 30.9104 27.4655 30.9104 26.0956C30.9104 25.03 30.9085 23.3404 30.507 21.92C30.3069 21.2122 30.014 20.5962 29.5933 20.1598C29.1801 19.7311 28.6292 19.4599 27.872 19.4599H24.986C24.2398 19.4599 23.6966 19.7302 23.2887 20.1585C22.8732 20.5948 22.5837 21.2108 22.3859 21.9191ZM24.986 18.8669H27.872C28.7751 18.8669 29.4695 19.1977 29.9903 19.738C30.5036 20.2706 30.8334 20.9907 31.0484 21.7513C31.4752 23.2611 31.4752 25.0286 31.4751 26.0817V26.0956C31.4751 27.6314 30.927 28.6987 30.0783 29.3727C29.2421 30.0368 28.1466 30.2941 27.0813 30.2941H25.8558L25.8504 30.294C24.7681 30.2726 23.6647 30.0049 22.8249 29.3421C21.972 28.669 21.4224 27.6126 21.4224 26.0956L21.4224 26.0818C21.4224 25.0287 21.4223 23.2616 21.8439 21.7522C22.0563 20.992 22.382 20.272 22.8894 19.7392C23.4043 19.1985 24.0916 18.8669 24.986 18.8669Z" fill="black" />
    <path fillRule="evenodd" clipRule="evenodd" d="M24.8673 29.6178C25.0233 29.6178 25.1497 29.7506 25.1497 29.9143V40.4181L26.4487 41.782L27.7476 40.4181V39.4184L26.4447 38.0503L27.6307 36.805L26.4447 35.5597L27.6307 34.3144L26.4447 33.0691L27.7476 31.701V29.9143C27.7476 29.7506 27.8741 29.6178 28.03 29.6178C28.186 29.6178 28.3124 29.7506 28.3124 29.9143V31.9466L27.2434 33.0691L28.4294 34.3144L27.2434 35.5597L28.4294 36.805L27.2434 38.0503L28.3124 39.1728V40.6637L26.4487 42.6207L24.585 40.6637V29.9143C24.585 29.7506 24.7114 29.6178 24.8673 29.6178Z" fill="black" />
    <path d="M27.2194 22.4842H25.6783C25.0082 22.4842 24.4722 21.9308 24.4722 21.2389C24.4722 20.5471 25.0082 19.9936 25.6783 19.9936H27.2194C27.8895 19.9936 28.4255 20.5471 28.4255 21.2389C28.4255 21.8616 27.8895 22.4842 27.2194 22.4842Z" fill="white" />
    <path fillRule="evenodd" clipRule="evenodd" d="M25.6784 20.2899C25.1598 20.2899 24.7547 20.7153 24.7547 21.2387C24.7547 21.7621 25.1598 22.1875 25.6784 22.1875H27.2196C27.7281 22.1875 28.1433 21.7035 28.1433 21.2387C28.1433 20.7153 27.7382 20.2899 27.2196 20.2899H25.6784ZM24.1899 21.2387C24.1899 20.3784 24.8569 19.6969 25.6784 19.6969H27.2196C28.0411 19.6969 28.708 20.3784 28.708 21.2387C28.708 22.0192 28.0511 22.7805 27.2196 22.7805H25.6784C24.8569 22.7805 24.1899 22.099 24.1899 21.2387Z" fill="black" />
    <path fillRule="evenodd" clipRule="evenodd" d="M25.2217 22.4573C25.2217 20.1753 27.3542 18.3333 29.8325 18.3333C31.0673 18.3333 32.342 18.7783 33.312 19.498C34.2816 20.2175 34.9995 21.2547 34.9995 22.4573C34.9995 23.5519 34.6906 24.6444 34.0491 25.4768C33.3972 26.3228 32.4062 26.8889 31.1159 26.8889V25.9487C32.0501 25.9487 32.7488 25.5533 33.2306 24.9281C33.7228 24.2893 33.9914 23.4011 33.9914 22.4573C33.9914 21.6214 33.4901 20.8316 32.6844 20.2338C31.879 19.6362 30.8223 19.2735 29.8325 19.2735C27.8618 19.2735 26.2297 20.7392 26.2297 22.4573H25.2217Z" fill="black" />
  </SvgIcon>
);

export default OwnerTerms;
