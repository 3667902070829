import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const InsuranceProtectionPlan = (props) => (
  <SvgIcon
    {...props}
    width="31"
    height="38"
    viewBox="0 0 31 38"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M13.5752 25.5083L23.9335 15.15L21.321 12.5375L13.5752 20.2833L9.72516 16.4333L7.11266 19.0458L13.5752 25.5083ZM15.5002 37.3333C11.2529 36.2639 7.74669 33.8271 4.98141 30.0229C2.21614 26.2188 0.833496 21.9944 0.833496 17.35V6.16667L15.5002 0.666672L30.1668 6.16667V17.35C30.1668 21.9944 28.7842 26.2188 26.0189 30.0229C23.2536 33.8271 19.7474 36.2639 15.5002 37.3333ZM15.5002 33.4833C18.6779 32.475 21.3057 30.4583 23.3835 27.4333C25.4613 24.4083 26.5002 21.0472 26.5002 17.35V8.6875L15.5002 4.5625L4.50016 8.6875V17.35C4.50016 21.0472 5.53905 24.4083 7.61683 27.4333C9.69461 30.4583 12.3224 32.475 15.5002 33.4833Z" fill="black" />
  </SvgIcon>
);

export default InsuranceProtectionPlan;
