import React, { useEffect, useState, useRef } from 'react';
// Material UI core
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Link from 'components/shared/Link';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import { useLayoutState } from 'components/shared/Layout';

const useStyles = makeStyles((theme) => ({
  root: {
    lineHeight: theme.typography.pxToRem(13),
    fontSize: theme.typography.pxToRem(12),
    fontWeight: '500',
    textTransform: 'uppercase',
    overflow: 'hidden',
    // marginBottom: theme.typography.pxToRem(45),
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      position: 'relative',
      overflowX: 'hidden',
    },
  },
  bcLinksCon: {
    display: 'flex',
    flexFlow: 'row',
    alignItems: 'center',
    flexWrap: 'nowrap',
    whiteSpace: 'nowrap',
    padding: '0 !important',
    [theme.breakpoints.down('sm')]: {
      transition: 'all .6s',
    },
  },
  bcNav: {
    padding: `0 ${theme.typography.pxToRem(15)}`,
    // position: 'absolute',
    width: theme.spacing(4),
    height: theme.spacing(4),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    boxSizing: 'borderBox',
    zIndex: '2',
    marginRight: theme.spacing(1),
    backgroundColor: theme.palette.common.white,
    '&::after': {
      content: "''",
      position: 'absolute',
      height: '70%',
      width: '1px',
      left: theme.spacing(4),
      backgroundColor: theme.palette.grey[50],
      top: 0,
      bottom: 0,
      margin: 'auto',
    },
  },
  bcArrow: {
    color: theme.palette.primary.main,
    '&.rotated': {
      transform: 'rotate(180deg)',
      transition: 'all 0.6s',
    },
  },

  bcListItem: {
    paddingRight: theme.typography.pxToRem(12),
    paddingLeft: 0,
  },
  bcLink: {
    color: theme.palette.primary.main,
    cursor: 'pointer',
  },
  bcSeparator: {
    color: theme.palette.grey[200],
    marginLeft: theme.typography.pxToRem(12),
  },
  bcText: {
    color: theme.palette.grey[200],
    fontWeight: '500',
    fontSize: theme.typography.pxToRem(12),
  },
}));

const Breadcrumbs = ({ data }) => {
  const classes = useStyles();
  const theme = useTheme();
  const isLargeDown = useMediaQuery(theme.breakpoints.down('lg'));
  const { layoutState } = useLayoutState();
  const [dir, setDir] = useState('right');
  const [showBcArrow, setShowBcArrow] = useState(true);
  const breadcrumbsWrapper = useRef(null);
  const breadcrumbs = useRef(null);
  const breadcrumbsArrow = useRef(null);

  const GetBcScrollValue = () => {
    const outerWidth = breadcrumbsWrapper.current.offsetWidth;
    const scrollWidthElm = breadcrumbs.current.scrollWidth;
    const scrollingValue = scrollWidthElm - outerWidth;
    if (scrollingValue <= 0) {
      setShowBcArrow(false);
    } else {
      setShowBcArrow(true);
      breadcrumbs.current.style.paddingLeft = theme.typography.pxToRem(55);
    }
    return scrollingValue;
  };
  const handleBcArrow = () => {
    // eslint-disable-next-line no-unused-expressions
    dir == 'right' ? setDir('left') : dir == 'left' && setDir('right');
    const scrollingValue = GetBcScrollValue();
    breadcrumbsArrow.current.classList.toggle('rotated');
    if (dir == 'right') {
      breadcrumbs.current.style.marginLeft = `${-scrollingValue}px`;
    }
    if (dir == 'left') {
      breadcrumbs.current.style.marginLeft = `${0}px`;
    }
  };

  const scrollToRightOnMobile = () => {
    isLargeDown && setDir('left');
    handleBcArrow();
  };

  useEffect(() => {
    if (layoutState.isCrawler || !breadcrumbsArrow.current) return;
    GetBcScrollValue();
  });
  useEffect(() => {
    if (layoutState.isCrawler || !breadcrumbsArrow.current) return;
    scrollToRightOnMobile();
  }, [breadcrumbsArrow]);

  return (
    <Box
      display="flex"
      flexDirection="row"
      alignItems="center"
      className={classes.root}
      ref={breadcrumbsWrapper}
    >
      {isLargeDown && showBcArrow && !layoutState.isCrawler && (
        <Box className={classes.bcNav} onClick={() => handleBcArrow()}>
          <KeyboardArrowRight ref={breadcrumbsArrow} className={classes.bcArrow} />
        </Box>
      )}
      <List
        className={classes.bcLinksCon}
        ref={breadcrumbs}
        itemScope
        itemType="https://schema.org/BreadcrumbList"
      >
        {data?.links.map((item, index) => (
          <ListItem
            className={classes.bcListItem}
            key={`${item.text}`}
            itemProp="itemListElement"
            itemScope
            itemType="https://schema.org/ListItem"
          >
            <Link
              className={classes.bcLink}
              href={item.path}
              translate={item.translate}
              itemProp="item"
              itemScope
              itemType="https://schema.org/Thing"
              itemID={`${item.path}${index}`}
            >
              <span itemProp="name">{item.text}</span>
            </Link>
            <meta itemProp="position" content={index + 1} />
            <span className={classes.bcSeparator}>\</span>
          </ListItem>
        ))}
        {data?.breadCrumbsTitle && (
          <ListItem
            className={classes.bcListItem}
            itemProp="itemListElement"
            itemScope
            itemType="https://schema.org/ListItem"
          >
            <Typography className={classes.bcText} variant="h3" itemProp="name">
              {data.breadCrumbsTitle}
              <meta itemProp="position" content={data.links.length + 1} />
            </Typography>
          </ListItem>
        )}
      </List>
    </Box>
  );
};

export default Breadcrumbs;
